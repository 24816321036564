import React from 'react';
import Filters from "./components/Filters";
import Options from "./components/Options";
import IngredientsList from "./components/IngredientsList";
import GenerateButton from "./components/GenerateButton";
import PizzaGenerator from "./components/PizzaGenerator";
import './App.css';
import PizzaGenerator2 from "./components/PizzaGenerator2";
import Layout from "./components/Layout";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import menuConfig from "./menuConfig";
function App() {
    const routes = menuConfig;

    return (
        <Layout>
            <Router>
                <Routes>
                    {routes.map((route, index) => (
                        <Route key={index} path={route.link} element={<route.component />} />
                    ))}
                </Routes>
            </Router>


        </Layout>
    );
}

export default App;

// src/components/Navbar.js
import React, { useState } from 'react';
import { FaPizzaSlice } from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive';
import menuConfig from '../menuConfig';

function Navbar() {
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <div className="bg-slate-900 text-white relativeegelmäßig  w-full p-4">
            <nav className="flex items-center justify-between mx-auto max-w-6xl ">
                <div className="flex items-center">
                    <FaPizzaSlice className="text-3xl mr-2" />
                    <span className="text-xl">Pizza Generator</span>
                </div>
                {isMobile ? (
                    <button onClick={toggleMenu} className="text-xl">
                        ☰
                    </button>
                ) : (
                    <ul className="flex space-x-4">
                        {menuConfig.map((item, index) => (
                            <li key={index}>
                                <a href={item.link}>{item.label}</a>
                            </li>
                        ))}
                    </ul>
                )}
            </nav>
            {isMobile && menuOpen && (
                <ul className="mt-2 w-[200px] absolute bg-slate-900 right-0">
                    {menuConfig.map((item, index) => (
                        <li key={index}>
                            <a href={item.link}>{item.label}</a>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}

export default Navbar;

// src/components/Layout.js
import React from 'react';
import Navbar from "./Navbar";

function Layout({ children }) {
    return (
        <div>
            <Navbar />
            <div className="flex flex-col pt-6 items-center w-vw min-h-screen bg-slate-950 text-white">


                {children}
            </div>
        </div>

    );
}

export default Layout;

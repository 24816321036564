import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faLock,
    faUnlock,
    faTrashAlt,
    faRedoAlt,
} from '@fortawesome/free-solid-svg-icons';

function Topping({ name, onRemove, onReroll, onFix, isLocked }) {



    return (
        <li className="flex w-full justify-between p-2 card-light text-xl mb-2">
            <span className="text-white">{name}</span>
            <div className="space-x-4">
                {onRemove && (
                    <button onClick={onRemove} className="primary-interaction">
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                )}
                <button onClick={onReroll} className="primary-interaction">
                    <FontAwesomeIcon icon={faRedoAlt} />
                </button>
                {onFix && (
                    <button onClick={onFix} className="primary-interaction">
                        <FontAwesomeIcon
                            icon={isLocked ? faLock : faUnlock}
                            style={{ color: isLocked ? '#F7A950' : 'gray' } }
                            // Das Schloss-Icon ändert sich je nach Zustand (isLocked)
                        />
                    </button>
                )}
            </div>
        </li>
    );
}

export default Topping;

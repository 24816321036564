// src/components/PizzaGenerator2.js
import React, {useEffect, useState} from 'react';
import Topping from "./Topping";
import ingredients from '../ingredients';
import saucen from "../sauces";
import belaege from "../toppings";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'; // Importiere die gewünschten Icons

function PizzaGenerator2() {
    const sauces = saucen;
    const availableToppings = belaege;
    
    const [sauce, setSauce] = useState('');
    const [toppings, setToppings] = useState([]);
    const [ingredientCount, setIngredientCount] = useState(3);
    const [fixedToppings, setFixedToppings] = useState([]);
    const [remainingToppings, setRemainingToppings] = useState([]);
    const [fixedSauce, setFixedSauce] = useState(true);




    const generatePizza = () => {
        const selectedToppings = [...toppings]; // Füge die fixierten Zutaten hinzu
        const availableToppingsCopy = [...remainingToppings]; // Kopie der verfügbaren Zutaten
        const updatedToppings = []; // Array für die neuen Zutaten

        for (let i = 0; i < ingredientCount; i++) {
            if(selectedToppings.length>i&&fixedToppings.includes(selectedToppings[i])) {

                   updatedToppings.push(toppings[i]);

            }else {

                const randomIndex = Math.floor(Math.random() * availableToppingsCopy.length);
                const newTopping = availableToppingsCopy[randomIndex];

                updatedToppings.push(newTopping);
                availableToppingsCopy.splice(randomIndex, 1);
            }

        }

        // Wenn die Option "Kategorie behalten" aktiviert ist, behalte die Kategorie der Sauce bei

            setSauce(getRandomSauce());


        setToppings(updatedToppings);
    };

// Funktion zum Hinzufügen einer Zutat zu den fixierten Zutaten
    const toggleFixTopping = (index, topping) => {
        const updatedFixedToppings = [...fixedToppings];
        if (updatedFixedToppings.includes(topping)) {
            updatedFixedToppings.splice(updatedFixedToppings.indexOf(topping), 1);
        }
        else {
            updatedFixedToppings.push(topping);
        }
        setFixedToppings(updatedFixedToppings);

    };

// Funktion zum Entfernen einer fixierten Zutat
    const removeFixedTopping = (index,topping) => {
        const updatedFixedToppings = [...fixedToppings];
        updatedFixedToppings.splice(topping, 1);

        setFixedToppings(updatedFixedToppings);
    };

// Funktion zum Entfernen einer Zutat aus den fixierten Zutaten
    const removeTopping = (index, topping) => {
        if (fixedToppings.includes(topping)) {
            removeFixedTopping(index, topping);
        }
        const updatedToppings = [...toppings];
        updatedToppings.splice(index, 1);
        setRemainingToppings([...remainingToppings, topping])
        setToppings(updatedToppings);
        setIngredientCount(ingredientCount - 1)
    };


    const getRandomSauce = () => {
        if (fixedSauce) return sauce;
        const randomIndex = Math.floor(Math.random() * sauces.length);
        const newSauce = sauces[randomIndex];
        if (newSauce === sauce) {
            return  getRandomSauce();
        }
        return newSauce;
    }

    const toggleFixSauce = () => {
        setFixedSauce(!fixedSauce);
    }

    const rerollTopping = (index) => {
        const updatedToppings = [...toppings]; // Starte mit den festen Zutaten
        if (fixedToppings.includes(toppings[index])) {
            removeFixedTopping(index, toppings[index]);
        }

            const randomIndex = Math.floor(Math.random() * remainingToppings.length);
            const newTopping = remainingToppings[randomIndex];

            updatedToppings[index] = newTopping;
            remainingToppings.splice(randomIndex, 1);
            setRemainingToppings([...remainingToppings, toppings[index]])


        setToppings(updatedToppings);
    };

    const updateToppings = (names) => {
        const remainingToppingsCopy = [...availableToppings];
        setToppings([...toppings, ...names])
        // Entferne die toppings aus den verfügbaren Zutaten
        names.forEach(name => {
            const index = remainingToppingsCopy.indexOf(name);
            if (index > -1) {
                remainingToppingsCopy.splice(index, 1);
            }
        }
        )
        console.log(remainingToppingsCopy)
        setRemainingToppings(remainingToppingsCopy);


    }


    useEffect(() => {
        // Setze die verfügbaren Zutaten auf alle Zutaten
        // Zeige zu Beginn Tomatensauce und Mozzarella
        setSauce('Tomatensauce');
        updateToppings([
            'Mozzarella',
            'Basilikum',
            'Olivenöl'
        ]);

    }, []);

    return (
        <div className="md:min-w-[1000px]  flex flex-col items-center text-white p-6 rounded-xl shadow-md">
            <h1 className="text-6xl mb-8 font-extrabold bg-gradient-to-r from-red-500 to-primary text-transparent bg-clip-text mb-4 text-center">
                Pizza Generator
            </h1>
            {/* Toggle-Schalter für vegetarisch */}
            <div className="mb-4 w-full flex flex-col md:flex-row gap-4 ">
                <div className="w-full md:w-1/3 card mb-4 md:mb-0">
                    <p className="text-xl">Anzahl der Zutaten:</p>
                    <div className="flex items-center justify-center gap-4 mt-4">
                        <button
                            onClick={() =>
                                setIngredientCount(
                                    ingredientCount > 0 ? ingredientCount - 1 : 0
                                )
                            }
                            className="circle-button"
                        >
                            <FontAwesomeIcon icon={faMinus} />
                        </button>
                        <span className="text-2xl">{ingredientCount}</span>
                        <button
                            onClick={() =>
                                setIngredientCount(
                                    ingredientCount < availableToppings.length
                                        ? ingredientCount + 1
                                        : availableToppings.length
                                )
                            }
                            className="circle-button"
                        >
                            <FontAwesomeIcon icon={faPlus} />
                        </button>
                    </div>
                    <button
                        onClick={generatePizza}
                        type="button"
                        className="align-middle p-3 text-white font-bold font-verdana w-full mt-8 rounded-full primary-interaction-bg text-xl"
                    >
                        Pizza erstellen
                    </button>
                </div>

                <div className="w-full md:w-2/3 card">
                    <div className="mb-8">
                        <p className="text-2xl">Deine Pizza ist belegt mit: </p>
                    </div>
                    <div className="mb-4">
                        <ul className="list-disc list-inside">
                            <Topping
                                key={sauce}
                                name={sauce}
                                onReroll={() => setSauce(getRandomSauce)}
                                onFix={() => {toggleFixSauce()}}
                                isLocked={fixedSauce}
                                fontSize="xl"
                            />
                            {toppings.map((topping, index) => (
                                <Topping
                                    key={index}
                                    name={topping}
                                    onRemove={() => removeTopping(index,topping)}
                                    onReroll={() => rerollTopping(index)}
                                    onFix={() => toggleFixTopping(index,topping)}
                                    isLocked={fixedToppings.includes(topping)} // Übergebe den Zustand (isLocked) für jedes Topping                                    fontSize="xl"
                                />
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default PizzaGenerator2;

const belaege =
    [
        "Pepperoni",
        "Champignons",
        "Zwiebeln",
        "Salami",
        "Speck",
        "Extra Käse",
        "Schwarze Oliven",
        "Grüne Paprika",
        "Ananas",
        "Spinat",
        "Knoblauch",
        "Artischockenherzen",
        "Feta Käse",
        "Schinken",
        "Jalapeños",
        "Sardellen",
        "Rote Zwiebeln",
        "Kirschtomaten",
        "Grüne Oliven",
        "Basilikum",
        "Oregano",
        "Parmesan Käse",
        "Ricotta Käse",
        "Getrocknete Tomaten",
        "Pesto Sauce",
        "Provolone Käse",
        "Ziegenkäse",
        "Cheddar Käse",
        "Blauschimmelkäse",
        "Koriander",
        "Gezupftes Schweinefleisch",
        "Hühnchen",
        "Rindfleisch",
        "Pepperoncini",
        "Karamellisierte Zwiebeln",
        "Buffalo Sauce",
        "Barbecue Sauce",
        "Geröstete Paprika",
        "Kapern",
        "Pfirsiche",
        "Rucola",
        "Auberginen",
        "Getrocknete Tomaten",
        "Mango",
        "Avocado",
        "Gerösteter Knoblauch",
        "Pinienkerne",
        "Trüffelöl",
        "Zucchini",
        "Olivenöl",
        "Pilze",
        "Paprika",
        "Ei",
        "Scharfe Peperoni",
        "Kichererbsen",
        "Lauch",
        "Mais",
        "Tofu",
        "Kreuzkümmel",
        "Sesam",
        "Estragon",
        "Rhabarber",
        "Rote Beete",
        "Rettich",
        "Currypulver",
        "Pfeffer",
        "Salz",
        "Thymian",
        "Rosmarin",
        "Kürbis",
        "Marinara Sauce",
        "Limetten",
        "Kokosnussmilch",
        "Lachs",
        "Garnelen",
        "Muscheln",
        "Sardinen",
        "Gorgonzola Käse",
        "Räucherlachs",
        "Gehackte Tomaten",
        "Paprikapulver"
    ]

export default belaege;
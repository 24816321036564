const saucen =
    [
    "Tomatensauce",
    "Pesto",
    "Bolognesesauce",
    "Béchamelsauce",
    "Sour Cream",
    "Ketchup",
        "Sauce Hollandaise",
        "Chilli-Sauce",
    "Barbecue-Sauce",
    "Oliven Öl und Knoblauch",
    "Salsa",
    "Hummus"
]
export default saucen;